import * as React from 'react';
import { Link } from 'gatsby';
import { Embed } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { CenteredImage } from '../../../components/centered_image';
import { StaticImage } from 'gatsby-plugin-image';

// Youtube IDs for the videos used in this case study
const yt_id_1 = "hGD4PdrSxIE";

function GFRPDetectionLimit() {
  return (
    <>
      <H1>
        Investigating the Ultrasonic Inspection defect detection capability in
        Glass Fiber Reinforced Plastic (GFRP)
      </H1>

      <p>
        Attenuation of ultrasonic waves is caused by absorption and scattering.
        It results in a loss of sound amplitude as the wave travels through a
        material and is one of the key limitations affecting the performance of
        an ultrasonic inspection. In this case study we intend to look at the
        effects of wave attenuation on the defect detection capability in GFRP
        at a fixed frequency.
      </p>
      <p>
        GFRP is an ultrasonically attenuative material, however, owing to its
        light weight and good mechanical properties, it is widely used in
        aerospace, marine and automotive components, wind turbine blades and
        many other areas.
      </p>
      <p>
        The purpose of this case study is to show how attenuation changes the
        ability to find flaws in GFRP. This will help you choose the right probe
        frequency when planning an inspection.
      </p>

      <H2>Simulation Set-Up</H2>

      <p>
        The transducer used is a 0.5 MHz single conventional transducer
        operating in pulse-echo mode. No wedge is used in this simulation and
        the probe is placed in direct contact with the GFRP specimen. The
        specimen has a longitudinal wave velocity of 4310 m/s, a shear wave
        velocity 2024 m/s and a density of 2100 kg/m3.  The plate is 50 mm thick
        and 200 mm wide.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/00_simulation_setup.png"
            alt="Simulation setup."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>
      <p>
        A 2-D simulation using Salvus was set up to run for 40 microseconds with and without
        an attenuation coefficient of 100 dB/m (@ 0.5 MHz) and the results were
        judged on the detectability of a 2 mm Flat Bottomed Hole (FBH), placed 5
        mm from the backwall of the specimen.
      </p>
      <p>
        The figure below shows the numerical mesh created for the simulation and
        the FBH was created by removing elements from it.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/01_mesh.png"
            alt="The numerical mesh of the simulation."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>

      <H2>Results</H2>

      <p>
        This video shows the reflection of the 2mm reflector with no attenuation
        in the part:
      </p>
      <Embed
        placeholder={`https://img.youtube.com/vi/${yt_id_1}/0.jpg`}
        source="youtube"
        id={yt_id_1}
      />
      <p>
        We ran a total of 4 cases, with and without attenuation and with and
        without the FBH. The cases without the FBH were run to clearly see the
        indication from the FBH.
      </p>
      <p>
        The compute time for the 4 cases was less than 25 seconds using a
        standard laptop (2024 MacBook Air), below are the A-Scan results.
      </p>
      <p>
        The first A-scan shows the simulations run with and without the 2mm FBH,
        both with no attenuation.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/03_no_attenuation.png"
            alt="Results without attenuation."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>
      <p>
        The results are as expected. The signal from the FBH is clearly seen
        before the expected backwall signal and the backwall signal from the
        simulation with the FBH is reduced, due to the presence of the FBH.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/04_with_attenuation.png"
            alt="Results with attenuation."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>
      <p>
        The Second A-Scan shows the effect of attenuation with and without the
        FBH. Compared to the A-Scan in the first case, you can see that the peak
        signal amplitude, from the backwall reflection is a factor of 3 larger
        in the A-scan without attenuation.
      </p>
      <p>
        The third A-Scan shows the received signal of the FBH with and without
        attenuation.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/05_more_attenuation.png"
            alt="Results with and without attenuation."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>
      <p>
        Finally, we adjusted the attenuation to a level where it becomes barely
        detectable to see the signal from the FBH. The graph indicates that with
        the attenuation coefficient increased to 200 dB/m (@ 0.5 MHz), the
        amplitude received by the reflector is reduced further by 40%, hardly
        indistinguishable from the noise.
      </p>
      <p>
        <CenteredImage>
          <StaticImage
            src="./resources/06_more_attenuation.png"
            alt="Results with and without stronger attenuation."
            width={800}
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>

      <H2>Conclusion</H2>

      <p>
        Through Salvus simulations, ultrasonic inspection capability can be
        evaluated and optimized in a reduced time frame. This simulation allows
        for accurate allowances for material attenuation to determine inspection
        performance.
      </p>
      <p>
        Future studies could include adding the noise from specific equipment.
        This could be performed in two ways: either artificially adding noise to
        the signal or by calibrating the simulation against real test data.
      </p>
    </>
  );
}

export default withLayout(GFRPDetectionLimit);
